<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    fullscreen
    hide-overlay
  >
    <v-toolbar light color="grey lighten-5">
      <v-btn icon light @click="dismiss">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title v-if="pmp.name" class="text-uppercase">{{
        pmp.name
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <!-- <v-btn light text color="primary" @click="dismiss"> Close</v-btn> -->
        <!-- <v-btn light text color="primary" @click="dismiss"> Copy </v-btn> -->

        <v-btn light text @click="dismiss"> Cancel </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card color="grey lighten-3" class="rounded-0" elevation="0">
      <v-card-text>
        <v-container fluid>
          <v-row justify="space-between">
            <v-col cols="6">
              <v-card outlined>
                <span v-if="pmp.description">
                  <v-card-text>
                    <h5>Description</h5>
                    {{ pmp.description }}
                  </v-card-text>
                  <v-divider></v-divider>
                </span>
                <span v-if="pmp.verticals_targeting">
                  <v-card-text>
                    <h5>Verticals Targeting</h5>
                    {{ pmp.verticals_targeting }}
                  </v-card-text>
                  <v-divider></v-divider>
                </span>
                <span v-if="pmp.environment">
                  <v-card-text>
                    <h5>Environment</h5>
                    {{ pmp.environment }}
                  </v-card-text>
                  <v-divider></v-divider>
                </span>
                <span v-if="pmp.format">
                  <v-card-text>
                    <h5>Format</h5>
                    {{ pmp.format }}
                  </v-card-text>
                  <v-divider></v-divider>
                </span>
                <span v-if="pmp.available_devices">
                  <v-card-text>
                    <h5>Available Devices</h5>
                    {{ pmp.available_devices }}
                  </v-card-text>
                  <v-divider></v-divider>
                </span>
                <span v-if="pmp.available_sem_breakdown">
                  <v-card-text>
                    <h5>Available Sem Breakdown</h5>
                    {{ pmp.available_sem_breakdown }}
                  </v-card-text>
                  <v-divider></v-divider>
                </span>
              </v-card>
            </v-col>
            <v-col cols="6" v-if="pmp.impressions && pmp.uniques">
              <v-row>
                <v-col cols="6">
                  <v-card outlined>
                    <v-card-title class="display-1">{{
                      pmp.impressions
                    }}</v-card-title>
                    <v-card-subtitle class="overline grey--text lighten-5">
                      impressions
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card outlined>
                    <v-card-title class="display-1">{{
                      pmp.uniques
                    }}</v-card-title>
                    <v-card-subtitle class="overline grey--text lighten-5">
                      uniques
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "LearnMore",
  props: {
    pmp: {
      type: Object,
      required: true,
    },
    dialog: Boolean,
  },
  methods: {
    dismiss() {
      this.$emit("dismiss");
    },
  },
};
</script>
