<template>
  <div>
    <v-tabs v-model="discoveryTab" background-color="#F5F7FB">
      <!-- <main-topbar :tabs="tabs" /> -->
      <v-tab v-for="item in tabs" :key="item">
        <span class="text-capitalize">{{ item }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="discoveryTab" style="background: transparent">
      <v-tab-item style="background: transparent">
        <div class="mt-3">
          <v-row>
            <v-col cols="3">
              <v-select
                v-model="selectedCountry"
                :items="countries"
                label="Select Country"
                @change="changeCountry"
                :hide-selected="true"
                flat
                solo
                hide-details
                class="shrink"
                :menu-props="{ bottom: true, offsetY: true }"
              >
              </v-select
            ></v-col>
          </v-row>
        </div>
        <discovery-tab :country="selectedCountry" :kenya_forecast="forecast" />
      </v-tab-item>
      <v-tab-item>
        <v-row class="my-1">
          <v-col cols="12"><h4>Featured curated packages:</h4></v-col>
          <v-col
            cols="3"
            v-for="(item, index) in _pmpFeatured"
            :key="item.name"
          >
            <v-card outlined class="mx-auto" height="100%">
              <v-alert
                class="pa-0 ma-0"
                border="top"
                colored-border
                :color="colors[index]"
                dense
              >
                <div class="d-flex flex-column">
                  <v-card-title class="py-1">
                    <h6 class="text-capitalize mt-1">
                      {{ item.name }}
                    </h6>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="mb-5 pb-5">
                    <span> {{ item.description.substring(0, 56) }}...</span>
                  </v-card-text>
                  <v-card-actions class="mt-auto">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click.stop="showDetails(item)">
                      View
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-alert>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card outlined>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Description</th>
                      <th class="text-left">Impressions</th>
                      <th class="text-left">Uniques</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in _pmpTable" :key="item.name">
                      <td>
                        <a
                          class="font-weight-bold"
                          @click.stop="showDetails(item)"
                          >{{ item.name }}</a
                        >
                      </td>
                      <td>{{ item.description }}</td>
                      <td>{{ item.impressions }}</td>
                      <td>{{ item.uniques }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols>
            <learn-more
              :dialog="dialog"
              :pmp="item"
              @dismiss="dismissFn($event)"
            ></learn-more
          ></v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// import MainTopbar from "../../components/Layouts/Topbars/MainTopbar.vue";
import DiscoveryTab from "../../components/Tabs/DiscoveryTab.vue";
// import FilterInventory from "../../components/Discovery/FilterInventory.vue";
import LearnMore from "./LearnMore.vue";
import pmpData from "./curatedpmp";

export default {
  data: () => ({
    pmpData,
    dialog: false,
    item: {},
    colors: ["blue", "purple", "red", "orange", "yellow", "green"],
    discoveryTab: null,
    tabs: ["Sites & Apps", "Tradedesk Curated Markets"],
    selectedCountry: "kenya",
    countries: [
      { text: "Global", value: "global" },
      { text: "Kenya", value: "kenya" },
      { text: "Uganda", value: "uganda" },
      { text: "Ethiopia", value: "ethiopia" },
      { text: "Nigeria", value: "nigeria" },
      { text: "South Africa", value: "south_africa" },
      { text: "Senegal", value: "senegal" },
      { text: "Angola", value: "angola" },
      { text: "Gabon", value: "gabon" },
    ],
  }),
  components: {
    // MainTopbar,
    DiscoveryTab,
    LearnMore,
  },
  async mounted() {
    // Get Kenya Data
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch(
        "dsp/getCountryDiscoveryData",
        this.selectedCountry
      );
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
      this.$store.commit("makingApiRequest", false);
    }
  },
  computed: {
    forecast() {
      if (this.selectedCountry == "kenya") {
        return this.$store.state.dsp.kenya_discovery_data.forecast;
      } else if (this.selectedCountry == "nigeria") {
        return this.$store.state.dsp.nigeria_discovery_data.forecast;
      } else if (this.selectedCountry == "angola") {
        return this.$store.state.dsp.angola_discovery_data.forecast;
      } else if (this.selectedCountry == "senegal") {
        return this.$store.state.dsp.senegal_discovery_data.forecast;
      }else if (this.selectedCountry == "gabon") {
        return this.$store.state.dsp.gabon_discovery_data.forecast;
      } else if (this.selectedCountry == "ethiopia") {
        return this.$store.state.dsp.ethiopia_discovery_data.forecast;
      } else if (this.selectedCountry == "south_africa") {
        return this.$store.state.dsp.south_africa_discovery_data.forecast;
      } else if (this.selectedCountry == "uganda") {
        return this.$store.state.dsp.uganda_discovery_data.forecast;
      } else if (this.selectedCountry == "global") {
        return this.$store.state.dsp.global_discovery_data.forecast;
      }
      return this.$store.state.dsp.kenya_discovery_data.forecast;
    },
    _pmpFeatured() {
      const filteredPmp = this.pmpData.packages.filter(
        (obj) =>
          obj.name === "Easter Holiday" ||
          obj.name === "Sports" ||
          obj.name === "Video Market Place (VCR)" ||
          obj.name === "Travel"
      );

      return filteredPmp;
    },
    _pmpTable() {
      const filteredPmp = this.pmpData.packages.filter(
        (obj) =>
          obj.name !== "Easter Holiday" &&
          obj.name !== "Sports" &&
          obj.name !== "Video Market Place (VCR)" &&
          obj.name !== "Travel"
      );

      return filteredPmp;
    },
  },
  methods: {
    async changeCountry() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "dsp/getCountryDiscoveryData",
          this.selectedCountry
        );
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    showDetails(item) {
      this.dialog = true;
      this.item = item;
    },
    dismissFn() {
      this.dialog = false;
      this.bannerShow = false;
      this.modalShow = false;
    },
  },
};
</script>
