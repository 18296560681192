export default {
  packages: [
    {
      name: "Easter Holiday",
      description: "Audience curation for Easter holiday season",
      verticals_targeting:
        "Hobbies & Leisure, Special Occasions, Holiday's & Seasonal Events, Travel & Transportation, Shopping",
      environment: "Web & Apps",
      format: "Display & Video",
      available_devices: "",
      available_sem_breakdown: "",
      impressions: "87.9M",
      uniques: "4.7M",
      color: "red",
    },
    {
      name: "Video Market Place (VCR)",
      description:
        "All video inventory with a maximum video ad duration of 15 seconds, including bumper ads. Ads.txt targeting: direct & reseller. Minimum predicted viewability: 50%",
      verticals_targeting: "Video",
      environment: "Web & Apps",
      format: "Video",
      available_devices: "",
      available_sem_breakdown: "",
      impressions: "408M",
      uniques: "16.5M",
      color: "orange",
    },
    {
      name: "Sports",
      description:
        "Web. Vertical targeting: sports, sports games & fitness. Ads.txt targeting: direct & reseller. Minimum predicted viewability: 70%",
      verticals_targeting:
        "Sports, Games › Computer & Video Games › Fighting Games, Games › Computer & Video Games › Sports Games, Beauty & Fitness › Fitness",
      environment: "Web",
      format: "Display & Video",
      available_devices: "",
      available_sem_breakdown: "",
      impressions: "64.2M",
      uniques: "2.94M",
      color: "orange",
    },
    {
      name: "Travel",
      description:
        "Web. Vertical targeting: Travel & transportation, Travel insurance. Ads.txt targeting: direct & reseller. Minimum predicted viewability: 70%",
      verticals_targeting:
        "Travel & Transportation, Finance › Insurance › Travel Insurance",
      environment: "Web",
      format: "Display & Video",
      available_devices: "",
      available_sem_breakdown: "",
      impressions: "38.1M",
      uniques: "2.29M",
      color: "orange",
    },
    {
      name: "Full Screen Market Place Interstitial",
      description:
        "All full screen mobile app interstitial display inventory. Open Measurement & App-Ads.txt enabled.",
      verticals_targeting: "App",
      environment: "App",
      format: "Display",
      available_devices: "",
      available_sem_breakdown: "",
      impressions: "38.1M",
      uniques: "2.29M",
      color: "orange",
    },
    {
      name: "E-commerce",
      description:
        "Audience curation for commerce campaigns aimed at conversions",
      verticals_targeting:
        "Shopping, business services, online communities, commercial distribution, e-commerce services",
      environment: "Web & Apps",
      format: "Display & Video",
      available_devices: "",
      available_sem_breakdown: "",
      impressions: "229M",
      uniques: "8.69M",
      color: "yellow",
    },
    {
      name: "local publishers",
      description: "Local top sites collective in Kenya and other markets.",
      verticals_targeting: "Mobile (10,000+ Publishers)",
      environment: "Web & Apps",
      format: "Display & Video",
      available_devices: "",
      available_sem_breakdown: "",
      impressions: "137M",
      uniques: "4.34M",
      color: "green",
    },
    {
      name: "lock screen & adspace",
      description:
        "Access to Lock screens Ad space, direct first touch to users. ",
      verticals_targeting: "All Verticals",
      environment: "Mobile Lock Screens",
      format: "Display & Video",
      available_devices: "8,853,000",
      available_sem_breakdown: "",
      impressions: "",
      uniques: "",
      color: "blue",
    },
    {
      name: "Social Economic Measure (SEM)",
      description: "Audience curation by SEMs for different campaigns.",
      verticals_targeting: "",
      environment: "Web",
      format: "",
      available_devices: "",
      available_sem_breakdown: "High Income, Middle Income, Low Income",
      impressions: "",
      uniques: "",
      color: "indigo",
    },
  ],
};
